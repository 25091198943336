body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #252A33;
}

html, body {
  height: 100%;
  background-color: #043cca !important;
  background-image: radial-gradient(circle at 50% 0, #1336b2, #08197a 82%);
  color: #252A33;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  padding: 1rem 0;
  align-items: center;
}

.header-logo {
  height: 120px;
}

.bingo-grid {
  display: grid;
  margin: 1rem auto;
  width: 100%;
  max-width: 60vh;
  max-height: 100vw;
  padding: 0 15px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.5fr repeat(5, 1fr);
}

.bingo-cell {
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin: 0.25rem;
  border: none;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  background-color: #fff;
  font-size: 3rem;
  font-weight: 900;
  color: #08197a;
}

.bingo-header {
  aspect-ratio: initial;
  cursor: initial;
  color: #fff;
  font-weight: 900;
  font-size: 3rem;
  background-color: initial;
}

.bingo-cell:hover:not(.bingo-header) {
  background-color: #F7FAFF
}

.bingo-cell--selected {
  background-color: #2962ff !important;
  color: #fff;
}

.bingo-cell:focus {
  box-shadow: 0 0 0 0.0625rem #fff, 0 0 0 0.125rem #2962ff, 0 0 0 0.375rem rgba(18, 122, 255, 0.13);
  z-index: 100;
}

.bingo-free {
  width: 5rem;
  height: 5rem;
}

@media screen and (max-height: 1024px) {
  .header-logo {
    height: 80px;
  }
  .bingo-free {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-height: 800px) {
  .header-logo {
    height: 40px;
  }
  .bingo-free {
    width: 3rem;
    height: 3rem;
  }
  .bingo-cell {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .header-logo {
    height: 40px;
  }
  .bingo-free {
    width: 3rem;
    height: 3rem;
  }
  .bingo-cell {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .header-logo {
    height: 40px;
  }
  .bingo-free {
    width: 3rem;
    height: 3rem;
  }
  .bingo-cell {
    font-size: 1.5rem;
  }
}